import algoliasearch from "algoliasearch/lite"

import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from "./constants"

export const searchIndices = [
  { name: `collections`, title: `Collections` },
  { name: `articles`, title: `Articles` },
  { name: `events`, title: `Events` },
  { name: `programmes`, title: `Programmes` },
  { name: `students`, title: `Students` },
]

export const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)
