/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"

import { Footer } from "../navigation/Footer"
import { Header } from "../navigation/Header"
import { PageFooter } from "../navigation/PageFooter"
// KEEP BELOW
// import { ChatAppMount } from "../pagelayout/ChatAppMount"
import { ServiceWorkerRefresh } from "../pagelayout/ServiceWorkerRefresh"

import setScrollBarWidth from "set-scrollbar-width"

import * as styles from "./Layout.module.scss"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const layoutHeaderRef = useRef(null)

  useEffect(() => {
    setScrollBarWidth()
  }, [])

  return (
    <>
      <a href="#maincontent" className={styles.skip} tabIndex="0">
        Skip to main content
      </a>

      <div ref={layoutHeaderRef} />

      <Header siteTitle={data.site.siteMetadata.title} />

      <div
        className={styles.container}
        key={"container-" + props.location.pathname}
      >
        <main id="maincontent">{props.children}</main>

        <PageFooter />
      </div>

      <Footer location={props.location} />
      <ServiceWorkerRefresh />

      {/* <ChatAppMount /> */}
    </>
  )
}

export { Layout }
