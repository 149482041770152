import React from "react"

import * as styles from "./Mode.module.scss"
import { ModeButton } from "./ModeButton"

const ModeToggles = () => {
  return (
    <div className={styles.toggleWrapper}>
      <span className={styles.padded}>
        <ModeButton mode={0} />
      </span>
      <span className={styles.padded}>
        <ModeButton mode={2} />
      </span>
      <span>
        <ModeButton mode={1} />
      </span>
    </div>
  )
}

export { ModeToggles }
