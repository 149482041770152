import dayjs from "dayjs"

export const checkEitherExist = (one, orOther) => {
  if (one || orOther) {
    return true
  } else {
    return false
  }
}

export const checkEitherHasItems = (one, orOther) => {
  let check = false
  if (one) {
    if (one.length > 0) check = true
  }

  if (orOther) {
    if (orOther.length > 0) check = true
  }

  return check ? true : false
}

export const checkOneHasItems = (arr) => {
  let check = false

  arr.forEach((element, index) => {
    if (element?.length > 0) {
      check = true
    }
  })

  return check ? true : false
}

export const getGetDateTimeStringsArray = (isodatetime) => {
  const date = dayjs(isodatetime)
  return [
    date.format("dddd D MMMM"),
    `${date.format("HH:mm")} (GMT +${parseInt(
      date.format("Z").split(":").shift(),
    )})`,
  ]
}

export const getGetDateTimeString = (isodatetime, eventDisplayDate) => {
  const date = dayjs(isodatetime)
  const datetime = [
    date.format("dddd D MMMM"),
    `${date.format("HH:mm")} (GMT +${parseInt(
      date.format("Z").split(":").shift(),
    )})`,
  ]

  return eventDisplayDate || `${datetime[0]} ${datetime[1]}`
}

export const checkEventIsPresentFutureOrFeatured = (event) => {
  if (event.node.isFeatured === true) {
    return true
  }

  const now = new Date()
  const endDate = event.node.eventEndDate
    ? Date.parse(event.node.eventEndDate)
    : now.getTime() + 1000 * 60 * 60 * 3

  return now.getTime() < endDate
}

export const getValidTagsArray = (arrayItems) => {
  return arrayItems.filter(
    (item, index) => item && item.text !== null && item.text,
  )
}

export const getThemes = (themes) => {
  let tags = []

  themes.forEach((theme, index) => {
    const obj = { text: theme.themeName }
    // With Links below
    // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
    tags[index] = obj
  })
  return tags
}

export const getSchoolProgrammesFocus = (schoolsFocus, programmesFocus) => {
  const tags = []

  if (schoolsFocus) {
    schoolsFocus.forEach((school, index) => {
      const obj = { text: school.schoolName }
      // With Links below
      // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
      tags.push(obj)
    })
  }

  if (programmesFocus) {
    programmesFocus.forEach((programme, index) => {
      const obj = { text: programme?.programmeName }
      // With Links below
      // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
      tags.push(obj)
    })
  }

  // if (tags.length > 2) {
  //   return [{ text: "Cross-College" }]
  // }

  return tags
}

export const toLineBreaks = (text) => {
  return text.trim().split(/\n/).join("<br />")
}

export const toSanityLineBreaks = (text) => {
  if (text) return text.trim().split(/\n/).join("<br />").replace(/\\n/g, "")
  else return ""
}

export const checkIfSponsors = (sponsors) => {
  return sponsors?.length > 0 && sponsors[0].title !== ""
}

export const checkIfEventsEmpty = (sponsors) => {
  if (sponsors?.length > 0 && sponsors[0].title !== "") return true
  else return false
}

export const getRelativeStudentLink = (studentUrl) => {
  if (studentUrl?.includes("/students/")) {
    const urlArray = studentUrl.split("/students/")
    const relativeUrl = "/students/" + urlArray[urlArray.length - 1]
    return relativeUrl
  }

  if (studentUrl?.includes("/programmes/")) {
    const urlArray = studentUrl.split("/programmes/")
    const relativeUrl = "/programmes/" + urlArray[urlArray.length - 1]
    return relativeUrl
  }
}

export const getInternalWorkLink = (studentUrl) => {
  if (studentUrl?.includes("rca.ac.uk/students/")) {
    const urlArray = studentUrl.split("/students/")
    const relativeUrl = "/students/" + urlArray[urlArray.length - 1]
    return relativeUrl
  } else {
    return studentUrl
  }
}

export const truncateString = (str, n) => {
  if (str.length > n) {
    return str.substring(0, n) + "..."
  } else {
    return str
  }
}

export const getIrlLocationString = (location) => {
  switch (location) {
    case "battersea":
      return "Location: Battersea"
    case "kensington":
      return "Location: Kensington"
    case "whitecity":
      return "Location: White City"
    default:
      return null
  }
}
