import React, {
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import { wrapper, open as openCls } from "./Collapsible.module.scss"

const doubleRaf = () =>
  new Promise((resolve) =>
    requestAnimationFrame(() => requestAnimationFrame(() => resolve())),
  )

const Collapsible = ({
  children,
  open = false,
  duration = 500,
  timingFunction = "ease-in-out",
  onTransitionStart,
  onTransitionEnd,
}) => {
  const wrapperRef = useRef()
  const initialRef = useRef(false)

  const [mountContent, setMountContent] = useState(open)
  const [internalOpened, setInternalOpened] = useState(open)

  const transitionState = useCallback(
    async (el, direction, animate = true) => {
      if (!el) {
        return
      }

      if (direction && !internalOpened) {
        setMountContent(true)
      }
      setInternalOpened(direction)

      await doubleRaf()

      requestAnimationFrame(() => {})
      if (onTransitionStart && animate) {
        onTransitionStart(direction)
      }

      el.classList.toggle(openCls, direction)
      const last = el.getBoundingClientRect()

      el.classList.toggle(openCls, !direction)
      const first = el.getBoundingClientRect()

      const onDone = () => {
        if (!direction) {
          setMountContent(false)
        }

        el.classList.toggle(openCls, direction)

        if (onTransitionEnd && animate) {
          onTransitionEnd(direction)
        }
      }

      if (animate) {
        const anim = el.animate(
          [
            {
              height: `${first.height}px`,
            },
            {
              height: `${last.height}px`,
            },
          ],
          {
            duration,
            fill: "forwards",
            timingFunction,
          },
        )
        anim.onfinish = () => {
          anim.cancel()
          onDone()
        }
      } else {
        onDone()
      }
    },
    [
      duration,
      onTransitionEnd,
      onTransitionStart,
      internalOpened,
      timingFunction,
    ],
  )

  const setRef = useCallback(
    (ref) => {
      if (ref) {
        wrapperRef.current = ref

        if (open && !initialRef.current) {
          transitionState(wrapperRef.current, open, false)
        }
        initialRef.current = true
      }
    },
    [open, transitionState],
  )

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    if (wrapperRef.current && open !== internalOpened) {
      transitionState(wrapperRef.current, open)
    }
  }, [open, transitionState, internalOpened])

  return (
    <div ref={setRef} className={classNames(wrapper)}>
      {mountContent && children}
    </div>
  )
}

Collapsible.propTypes = {
  children: PropTypes.node,

  // Whether or not the collapsed element is open
  open: PropTypes.bool,

  onAnimationComplete: PropTypes.func,
  onAnimationStart: PropTypes.func,
}

export { Collapsible }
