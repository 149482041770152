import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import {
  grid,
  gap as gapCls,
  padded as paddedCls,
  paddedX as paddedXCls,
  paddedTlr as paddedTlrCls,
  paddedLr as paddedLrCls,
} from "./StandardGrid.module.scss"

const StandardGrid = ({
  tagName: El = "div",
  className,
  total = 2,
  desktopTotal,
  gap = false,
  padded = false,
  paddedTLR = false,
  paddedLR = false,
  paddedX = false,
  children,
  ...props
}) => {
  return (
    <El
      className={classNames(
        grid,
        {
          [gapCls]: gap,
          [paddedCls]: padded,
          [paddedTlrCls]: paddedTLR,
          [paddedLrCls]: paddedLR,
          [paddedXCls]: paddedX,
        },
        className,
      )}
      style={{
        "--standard-grid-total": total,
        "--standard-grid-desktop-total": desktopTotal || total,
      }}
      {...props}
    >
      {children}
    </El>
  )
}

StandardGrid.propTypes = {
  total: PropTypes.number,
  gap: PropTypes.bool,
  padded: PropTypes.bool,
}

export { StandardGrid }
