import React, { useState, useEffect, useRef, useCallback } from "react"

import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"

import { MENUS, useRCAContext } from "../../../context/RCAContext"
import { clickOutside } from "../../../utils/common"
import { Collapsible } from "../../common/Collapsible"
import { ListItem } from "../../common/ListItem"
import { PlusIcon } from "../../common/PlusIcon"
import { StandardGrid } from "../../common/StandardGrid"
import * as styles from "./Menu.module.scss"
import { SchoolMenuItem } from "./SchoolMenuItem"

const STATIC_LINKS = [
  {
    to: "/programmes",
    label: "Programmes",
  },
  {
    to: "/students",
    label: "Students",
  },
  {
    to: "/events",
    label: "Events",
  },
  {
    to: "/themes",
    label: "Themes",
  },
  {
    to: "/collections",
    label: "Collections",
  },
  // {
  //   to: "/features",
  //   label: "Features",
  // },
  // {
  //   to: "/collaborations",
  //   label: "Collaborations",
  // },
  // {
  //   to: "/stories",
  //   label: "Stories",
  // },
  {
    to: "/tags",
    label: "Tags",
  },
  {
    to: "https://www.rca.ac.uk/",
    label: "About RCA",
  },
]

const Menu = ({ onReportRef, animating }) => {
  const { isNavOpen, closeMenu, currentMenuRef, bodyLocked } = useRCAContext()
  const listRef = useRef(null)

  const data = useStaticQuery(graphql`
    query SchoolsMenuQuery {
      allSanitySchool(sort: { schoolName: ASC }) {
        nodes {
          schoolName
          slug {
            current
          }
          programmes {
            programmeName
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const [schoolsSubmenu, setSchoolsSubmenu] = useState(false) // Need to determine start state if sub route

  const schools = data.allSanitySchool.nodes

  /***** NOTE NOTE *******/
  // The call to closeMenu in this useEffect causes menu not to work
  useEffect(() => {
    // if current click is the menu button return
    // if (animating) return

    if (isNavOpen && listRef.current) {
      return clickOutside(listRef.current, () => {
        if (currentMenuRef.current !== MENUS.SEARCH) {
          closeMenu()
        }
      })
    }
  }, [isNavOpen, closeMenu, currentMenuRef, animating])

  const onRef = useCallback(
    (ref) => {
      listRef.current = ref

      onReportRef(ref)
    },
    [onReportRef],
  )

  return (
    <StandardGrid
      tagName="nav"
      className={classNames(styles.menu, { [styles.bodyLocked]: bodyLocked })}
      // Not sure why this is here as a boolean??? causes an error
      // onClick
    >
      <ul ref={onRef} className={styles.items}>
        <li>
          <ListItem
            to="/"
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
          >
            Home
          </ListItem>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => setSchoolsSubmenu(!schoolsSubmenu)}
            slot={<PlusIcon toggle={schoolsSubmenu} name="plus" />}
          >
            Schools
          </ListItem>

          <ul className="list-reset">
            <Collapsible duration={250} open={schoolsSubmenu}>
              {schools.map((school, i) => (
                <SchoolMenuItem key={school.slug.current} school={school} />
              ))}
            </Collapsible>
          </ul>
        </li>
        {STATIC_LINKS.map((item) => (
          <li key={item.to}>
            <ListItem
              to={item.to}
              rightBorder={false}
              leftBorder={false}
              topBorder={false}
              size="navigation"
            >
              {item.label}
            </ListItem>
          </li>
        ))}
      </ul>
    </StandardGrid>
  )
}

export { Menu }
