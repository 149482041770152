import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ToggleSwitch.module.scss"

/*
Toggle Switch Component from:
https://www.sitepoint.com/react-toggle-switch-reusable-component/
https://codesandbox.io/s/react-toggle-component-de4uy?from-embed=&file=/src/components/ToggleSwitch.scss:0-2482

Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch = ({
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
  labelVisible,
  label = { preText: "Alt-text", text: "toggle", id: "toggle" },
}) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32 && e.keyCode !== 13) return

    e.preventDefault()
    onChange(!checked)
  }

  return (
    <div className={styles.toggleSwitchWrapper}>
      <div className={styles.toggleSwitchPre}>{label.preText}</div>
      <div
        className={
          styles.toggleSwitch + (small ? ` ${styles.smallSwitch}` : "")
        }
      >
        <input
          type="checkbox"
          name={name}
          className={styles.toggleSwitchCheckbox}
          id={label.id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        {label.id ? (
          <label
            className={styles.toggleSwitchLabel}
            tabIndex={disabled ? -1 : 0}
            onKeyDown={(e) => handleKeyPress(e)}
            htmlFor={label.id}
          >
            <span className={"visually-hidden"}>{label.text}</span>
            <span
              className={
                disabled
                  ? `${styles.toggleSwitchInner} ${styles.toggleSwitchDisabled}`
                  : styles.toggleSwitchInner
              }
              data-yes={optionLabels[0]}
              data-no={optionLabels[1]}
              tabIndex={-1}
            />
            <span
              className={
                disabled
                  ? `${toggleSwitchSwitch} ${toggleSwitchDisabled}`
                  : styles.toggleSwitchSwitch
              }
              tabIndex={-1}
            />
          </label>
        ) : null}
      </div>
    </div>
  )
}

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ["yes", "no"],
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
}

export { ToggleSwitch }
