import React, { useCallback, useMemo } from "react"
import classNames from "classnames"
import { ToggleSwitch } from "../../common/ToggleSwitch/"
import { useRCAContext } from "../../../context/RCAContext"
import * as styles from "./AltMode.module.scss"

const AltButton = ({ label }) => {
  const { altMode, setAltMode } = useRCAContext()

  const altModeButtonClick = useCallback(() => {
    setAltMode(!altMode)
  }, [altMode, setAltMode])

  // https://www.sitepoint.com/react-toggle-switch-reusable-component/
  return (
    // <button
    //   className={classNames(styles.btn, {
    //     [styles.on]: altMode,
    //     [styles.off]: !altMode,
    //   })}
    //   onClick={altModeButtonClick}
    // >
    //   alt
    // </button>
    <div>
      <ToggleSwitch
        // disabled={!newsletter}
        checked={altMode}
        onChange={setAltMode}
        optionLabels={["yes", "no"]}
        label={label}
        labelVisible
      />
    </div>
  )
}

export { AltButton }
