import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { toSanityLineBreaks } from "../../../utils/data"
import { ClientOnly } from "../../common/ClientOnly"
import { InternalExternalLink } from "../../common/InternalExternalLink"
import { RCALogo } from "../../common/RCALogo"
import { StandardGrid } from "../../common/StandardGrid"
import * as styles from "./PageFooter.module.scss"

function PageFooter() {
  const { footerData } = useStaticQuery(graphql`
    query FooterQuery {
      footerData: sanitySingletonHomepage {
        externalLinks {
          linkName
          url
        }
        internalLinks {
          linkName
          url
        }
        rcaInfo
        rcaTMInfo
      }
    }
  `)

  return (
    <StandardGrid gap className={styles.wrapper}>
      {/* Logo  */}
      <div className={styles.logo}>
        {/* only load logo client side */}
        <ClientOnly>
          <RCALogo />
        </ClientOnly>
      </div>

      <div className={styles.content}>
        <div className={styles.box}>
          <h2 className={styles.heading}>Social</h2>

          <ul className={styles.links}>
            {footerData?.externalLinks?.map((link, i) => {
              return (
                <li key={`social-link${i}`}>
                  <InternalExternalLink to={link.url}>
                    {link.linkName}
                  </InternalExternalLink>
                </li>
              )
            })}
          </ul>
        </div>

        <div className={styles.box}>
          <h2 className={styles.heading}>About</h2>

          <ul className={styles.links}>
            {footerData?.internalLinks?.map((link, i) => {
              return (
                <li key={`about-link${i}`}>
                  <InternalExternalLink to={link.url}>
                    {link.linkName}
                  </InternalExternalLink>
                </li>
              )
            })}
          </ul>
        </div>

        <div className={styles.box}>
          <h2 className={styles.heading}>Royal College of Art</h2>

          <p
            dangerouslySetInnerHTML={{
              __html: toSanityLineBreaks(footerData?.rcaInfo),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: toSanityLineBreaks(footerData?.rcaTMInfo),
            }}
          />
        </div>
      </div>
    </StandardGrid>
  )
}

export { PageFooter }
