// extracted by mini-css-extract-plugin
export var altSwitch = "AltMode-module--alt-switch--e7657";
export var btn = "AltMode-module--btn--1f60d";
export var checkbox = "AltMode-module--checkbox--5a72e";
export var container = "AltMode-module--container--67d6b";
export var inner = "AltMode-module--inner--74d65";
export var label = "AltMode-module--label--c7044";
export var off = "AltMode-module--off--6c437";
export var on = "AltMode-module--on--2b2c6";
export var toggleAltSwitch = "AltMode-module--toggle-alt-switch--0e024";
export var toggleWrapper = "AltMode-module--toggle-wrapper--9e8d3";