import React from "react"

import * as styles from "./AltMode.module.scss"
import { AltButton } from "./AltButton"

const AltToggles = ({ label }) => {
  return (
    <div className={styles.toggleWrapper}>
      <AltButton label={label} />
    </div>
  )
}

export { AltToggles }
